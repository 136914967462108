import React, { useState, useEffect } from 'react';
import { FaStopwatch } from 'react-icons/fa';


export default function CountDownTime({startTime, interval,remaining}) {

    const [timeLeft, setTimeLeft] = useState(startTime);
    const [startTimestamp, setStartTimestamp] = useState(Date.now());
    useEffect(() => {
      const updateTimeLeft = () => {
          const elapsedTime = (Date.now() - startTimestamp) / 1000; // Time passed in seconds
          setTimeLeft(Math.max(startTime - elapsedTime, 0));
      };

      const timer = setInterval(updateTimeLeft, interval);

      // Update the remaining time if a function is passed as a prop
      if (remaining) {
          remaining(parseInt(timeLeft));
      }

      return () => {
          clearInterval(timer);
      };
  }, [startTimestamp, startTime, interval, remaining,timeLeft]);

      const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
      };
  return (
    <div className="bg-dark py-2 px-3 d-flex align-items-center rounded gap-3">
  <FaStopwatch className="text-white fs-4" />
  <p className="text-white fw-bold font-monospace display-4 mb-0">
    {formatTime(timeLeft)}
  </p>
</div>
  )
}
